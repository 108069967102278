@import "@/assets/scss/style.scss";

.c-add-offer-item-price-manipulation {
    display: flex;
    flex-direction: column;

    &__form-item {
        width: 100%;
    }

    &__input {
        width: 100%;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-right: 8px;
    }

    &__row {
        padding-top: 12px;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
    }

    &__label {
        padding-bottom: 8px;
    }

    &__input {
        width: 100%;
    }

    &__submit {
        margin-top: 24px;
    }
}
.isLoading {
    opacity: 0;
    pointer-events: none;
}

.loading-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
}
