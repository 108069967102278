@import "@/assets/scss/style.scss";

section {
    margin-bottom: 16px;
}

.section-name {
    color: black;
    margin-bottom: 8px;
}

.row {
    color: #595959;
}

.exclamation-circle {
    font-size: 22px;
    color: #ffb200;
    margin-right: 16px;
    line-height: 18px;
}

h5 {
    display: flex;
    align-items: center;
}

.dropdown {
    width: 100%;
}

.deleteMessage {
    margin-bottom: 8px;
}

.assignMessage {
    margin-bottom: 16px;
}

.projectList {
    margin-bottom: 12px;
    height: 300px;
    overflow-y: auto;
}

.selectLabel {
    color: #8c8c8c;
}

.ant-modal-body.pdf-preview-body {
    height: 70vh;
    overflow-y: hidden;
    scrollbar-width: thin;
    background-color: $grey-three;
}

.title-header-container {
    display: flex;
    justify-content: space-around;
}

.header-actions {
    display: flex;
    justify-content: flex-end;
}

.title-header-container > * {
    flex: 1;
}

.header-actions > * + button {
    margin-left: 8px;
}

.pdf-preview-body::-webkit-scrollbar {
    width: 16px;
}

.pdf-preview-body::-webkit-scrollbar-thumb {
    background-color: $grey-1;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
}

.preview-file__img {
    max-width: 100%;
    height: 100%;
}

.pdf-app.light {
    --pdf-toolbar-color: grey;
    --pdf-app-background-color : $grey-three;
    --pdf-sidebar-content-color: $grey-three;
    --pdf-toolbar-sidebar-color: $grey-three;
  }
