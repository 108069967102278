@import "@/assets/scss/style.scss";

.color-module {
    &__result-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        width: 100%;
    }

    &__result {
        border: 1px solid rgb(231, 231, 231);
        padding: 1rem;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        margin-top: auto;
        margin-bottom: 25rem;
    }

    &__result-label {
        font-size: 1.2rem;
        font-weight: 600;
    }

    &__result-text {
        width: 100%;
    }

    ::v-deep .ant-form-item-control {
        line-height: normal;
    }
}
