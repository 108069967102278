@import "@/assets/scss/style.scss";

.co-edit-multiposition-popup-slot {
    color: $dark;
    position: relative;
    overflow: hidden;
    min-height: 100%;

    .c-add-product-row {
        &__wrapper {
            &:nth-child(3) {
                &::v-deep {
                    .c-add-product-row__icon-wrapper {
                        height: 44px;
                    }
                }
            }
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    &__upper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    &__upper-left,
    &__upper-right {
        flex: 1;
    }

    &__upper-right {
        text-align: left;
    }

    &__edit-multiposition-wrapper {
        padding-top: 10px;
        overflow-x: auto;
        position: relative;
        width: 100%;
    }

    &__product-label {
        margin: 2px;
    }

    &__product-label-title {
        padding-bottom: 5px;
    }

    &__bottom {
        padding-top: 20px;
        text-align: center;
    }

    &__top-bar {
        text-align: center;
    }

    section {
        padding-bottom: 20px;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }

    &__section-wrapper {
        margin-bottom: 4rem;
    }

    &__loading {
        position: absolute;
        top: 52px;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.6);

        .anticon {
            font-size: 40px;
        }
    }

    &__add-product-button {
        // margin-top: 30px;
        // margin-left: 40px;
        color: $grey-9;
    }

    &__footer {
        padding: 16px 24px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        box-shadow: inset 0 1px 0 #e2e4e9;
        width: 100%;
        z-index: 100;
        background-color: #fff;

        & > * {
            margin-right: 8px;

            &:first-child {
                margin-right: auto;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__footer--main {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        padding: 1.2rem 1.5rem;
        background-color: #f4f4f4;
        margin-right: 0.8rem;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1111;
        width: 100%;

        button:first-child {
            margin-right: 0.5rem;
        }
    }
}
