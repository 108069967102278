@import "@/assets/scss/style.scss";

.label {
    color: $cool-grey;
    padding-bottom: 5px;
}

button {
    padding: 6px;
}

h5 {
    color: $cool-grey;
    padding-bottom: 5px;
}
.ant-btn.no-dropdown {
    color: rgb(38, 88, 165);
    border-color: rgb(38, 88, 165);
    background-color: rgba(38, 88, 165, 0.125);
    pointer-events: none;
}
