@import "@/assets/scss/style.scss";

.ant-notification {
    white-space: pre-line;
}

.new-client-modal {
    .ant-modal {
        padding: 0;

        .ant-modal-title {
            text-align: center;
        }

        .ant-modal-header {
            border-bottom: none;
        }

        .ant-modal-header,
        .ant-modal-footer {
            border-radius: 0;
        }

        .ant-modal-body {
            height: calc(100vh - 108px);
            overflow-y: auto;
            padding: 0;
        }

        .ant-tabs {
            height: 100%;
            overflow-y: auto;
        }
    }
}

.new-client-modal {
    .main-title {
        font-weight: bold;
    }

    .header-section {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;

        .title {
            font-weight: bold;
            padding-right: 10px;
            color: rgba(0, 0, 0, 0.75);
        }

        .border {
            flex-grow: 2;
            border-bottom: 1px dashed $grey-4;
            height: 1px;
        }
    }

    .form-section {
        margin-bottom: 40px;
    }

    .label {
        span {
            color: $ant-red;
        }

        color: $grey-8;
    }
}
