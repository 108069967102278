@import "@/assets/scss/style.scss";

.contact-container {
    border-left: 6px solid $grey-4;
    padding-left: 10px;
    margin-bottom: 40px;

    .contact-information__title-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
    }
    .contact-information__title {
        font-weight: bold;
    }

    &:last-child {
        padding-bottom: 2rem;
    }
}
.checkbox-container {
    width: 100%;
    margin-top: 21px;
    border: 1px solid $grey-1;
    padding: 8px 10px;

    .ant-checkbox-wrapper {
        margin-right: 10px;
    }
}

.country-flag-container {
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-size: 30px;
    position: absolute;
    z-index: 100;
    top: 34px;
    left: 20px;
}

.responsive-row {
    & > * {
        min-width: fit-content;
    }
}

.select-country-wrapper {
    @media (max-width: 1450px) {
        width: 100%;
    }
}

.red-border {
    border-color: red;
}

::v-deep .section__input-container__emails,
::v-deep .section__input-container__phones {
    @media (max-width: 630px) {
        width: 100%;
    }
}
