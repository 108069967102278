@import "@/assets/scss/style.scss";

.product-popup-slot__notification-wrap {
    z-index: 1000;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 40px;
    right: 40px;
    width: 25rem;
    border-radius: 8px 8px 0 0;

    @media (max-width: 1200px) {
        max-width: 20rem;
    }
    .notifications-inner-wrap {
        height: auto;
        max-height: 70vh;
        overflow: auto;
        width: 100%;
        @media (max-width: 900px) {
            margin-top: 5px;
            margin-right: -35px;
        }
    }

    .product-popup-slot__notification-wrap-icon-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translate(6rem, 2px);

        .product-popup-slot__notification-wrap-icon {
            font-size: 24px;
        }

        .notification__caret-up {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                box-sizing: border-box;
                border: 10px solid black;
                border-color: transparent transparent #fff #fff;
                transform-origin: 0 0;
                transform: rotate(135deg);
                box-shadow: -1px 2px 3px 0 rgba(0, 0, 0, 0.1);
                @media (max-width: 900px) {
                    margin-top: 15px;
                }
            }
            @media (max-width: 900px) {
                margin-right: -10px;
            }
        }

        .notification__warning {
            cursor: pointer;
            margin-bottom: -10px;
            transform: translate(-14px, -25px);

            @media (max-width: 900px) {
                transform: translate(-14px, -25px);
                margin-bottom: -25px; //relates to unclickable empty space
                margin-right: -10px;
            }
        }
    }

    & div:first-of-type {
        border-radius: 15px 15px 0 0;
    }

    .notifications-animate-enter-active {
        animation: bounce-in 0.2s;
    }
    .notifications-animate-leave-active {
        animation: bounce-in 0.2s reverse;
    }
    @keyframes bounce-in {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.05);
        }
        100% {
            transform: scale(1);
        }
    }
}
