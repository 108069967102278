@import "@/assets/scss/style.scss";

.c-floating-delete-actions {
    position: fixed;
    bottom: 20px;
    background-color: #ffffff;
    display: flex;
    width: 100%;
    max-width: 440px;
    justify-content: space-between;
    align-items: center;
    padding: 12px 22px 12px 32px;
    box-shadow: 0 4px 12px #dee0eb;
    border-radius: 9px;
    left: 50%;
    transform: translateX(-50%);
}
