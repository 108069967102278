@import "@/assets/scss/style.scss";

.color-module {
    &__info-modal {
        display: flex;
        flex-direction: column;
        gap: 7px;

        span {
            color: #3b3b3b;
        }
    }

    &__info-modal-title {
        font-weight: 600;
        margin-bottom: 1rem;
    }

    &__info-modal-group {
        display: flex;
        flex-direction: column;
        gap: 3px;
        padding-left: 2rem;
    }

    &__info-modal-subtext {
        white-space: pre-wrap;
    }

    &__info-modal-bold {
        font-weight: bold;
    }

    &__info-modal-point {
        font-size: 1rem;
        margin-right: 5px;
    }
}
