@import "@/assets/scss/style.scss";

ul {
    list-style: none;
}
.context-menu {
    position: absolute;
    z-index: 1000;
}
.menu {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    // box-shadow: 0 10px 20px rgb(64, 64, 64 / 5%);
    padding: 8px;
    border: 2px solid #ececec;
    border-radius: 8px;
    background-color: #ffffff;
}
.menu > li > a {
    font: inherit;
    border: 0;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: unset;
    color: #000;
    font-weight: 500;
    transition: 0.5s linear;
    -webkit-transition: 0.5s linear;
    -moz-transition: 0.5s linear;
    -ms-transition: 0.5s linear;
    -o-transition: 0.5s linear;
}
.menu > li > a:hover {
    background: #f1f3f7;
    color: #4b00ff;
}

.field-icon {
    margin-right: 10px;
}
