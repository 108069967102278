@import "@/assets/scss/style.scss";

section {
    margin-bottom: 16px;
}

.section-name {
    color: black;
    margin-bottom: 8px;
}

.row {
    color: #595959;
}

.exclamation-circle {
    font-size: 22px;
    color: #ffb200;
    margin-right: 16px;
    line-height: 18px;
}

.ant-modal-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    span {
        width: fit-content;
    }
}

h5 {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.dropdown {
    width: 100%;
}

.deleteMessage {
    margin-bottom: 8px;
}

.selectLabel {
    color: #8c8c8c;
}

.assignMessage {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 24px;
}

.projectList {
    margin-bottom: 12px;
    height: auto;
    max-height: 300px;
    overflow-y: auto;
    padding: 12px;
    border-radius: 8px;
    background: #f1f4f5;
    list-style: none;

    li {
        height: 30px;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
}

.selectLabel {
    color: #8c8c8c;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 12px;
}

section {
    display: flex;
    flex-direction: column;
}
