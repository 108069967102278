@import "@/assets/scss/style.scss";

.c-checklist {
    &__order-button {
        border: none;
        border-radius: 18px;
        box-shadow: 0 2px 8px 0 rgba(38, 88, 165, 0.32);
        background-color: $mid-blue;
        color: $white-two;
        min-width: 240px;
        height: 45px;
        margin: 0 auto 0 auto !important;
        display: block;

        &.is-disabled {
            color: rgba(0, 0, 0, 0.25);
            background-color: $grey-three;
            border-color: $grey-1;
            text-shadow: none;
            box-shadow: none;
        }
    }
}

.configurator-buttons-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;

    .add {
        margin-right: 12px !important;
    }

    .check {
        margin-left: 12px !important;
    }
}
@media (max-width: 900px) {
    .c-checklist {
    &__order-button {
        min-width: 140px;
        height: 45px;
        margin: 0 auto 0 auto !important;
        display: block;
    }
}

.configurator-buttons-wrap {
    margin: auto;
    width: 100%;
    padding: 10px;
    .add {
        margin:5px!important;
        text-align: center;
    }

    .check {
        margin:5px!important;
        text-align: center;
    }
}
}
@media (max-width: 500px) {
    .configurator-buttons-wrap button {
        text-align: center;
        font-size: 16px;
        word-wrap: break-word; 
        white-space: normal;
    }
}

