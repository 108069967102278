@import "@/assets/scss/style.scss";

.c-table-filters {
    max-width: 275px;
    background-color: $white-two;
    border-right: 1px solid $grey-4;
    border-left: 1px solid $grey-4;
    height: calc(100% - 60px);
    position: fixed;
    left: calc(100% - 275px);
    top: 0px;
    margin-left: 0px;
    float: right;
    overflow-y: hidden;
    z-index: 5;
    &__header {
        display: flex;
        background-color: $white-two;
        flex-wrap: nowrap;
        height: 60px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $grey-4;
        padding: 0 5%;
        overflow-y: auto;
        overflow-x: hidden;

        &-button-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-close-button {
            margin-left: 1rem;
            font-size: 1rem;
            cursor: pointer;
        }
    }

    &__body {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 72px;
    }

    &__header-title {
        color: $black;
    }

    &__loading {
        margin-left: 6px;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 8px;
        padding: 0 16px;
    }

    &__input,
    &__dropdown,
    &__country-picker-wrapper,
    &__status-dropdown-wrapper {
        width: 100%;
    }

    &__status-dropdown {
        width: 100%;
    }

    &::v-deep {
        .co-editable-content-block__dropdown {
            width: 100%;
        }
    }

    &__country-picker-wrapper,
    &__status-dropdown-wrapper {
        display: flex;
    }
}
