@import "@/assets/scss/style.scss";

.history {
    max-height: 600px;
    overflow-y: auto;
}
.history-row {
    padding: 15px 0;
    &:not(:first-child) {
        border-top: 1px dotted $grey-4;
    }

    .timestamp {
        color: $grey-7;
    }
    .message {
        color: $grey-9;
    }
}
