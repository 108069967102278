@import "@/assets/scss/style.scss";

.ant-dropdown-menu {
    margin-top: 5px;

    &::after {
        position: absolute;
        content: '';
        top: -4px;
        left: 10px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
    }
}

.user-group-select__current {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    height: 36px;
    border-radius: 4px;
    background-color: $roltek-blue-6;

    .current-user-group {
        font-size: 14px;
        color: #fff;
        margin-right: 4rem;
    }

    .user-group-select__icon-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        i {
            margin: 0;
            color: #fff;
            opacity: 50%;
            font-size: 12px;

            &:first-child {
                margin-bottom: -2px;
            }

            &:last-child {
                margin-top: -2px;
            }
        }
    }
}
.o-section__vertical-navigation .o-section__user-group-picker {
    position: static !important;
}
.highlight {
    background: #e6f7ff;
}
