@import "@/assets/scss/style.scss";

.color-module-table {
    border: 1px solid #e3e3ff;
    border-radius: 5px;
    width: 100%;
    margin-top: auto;
    height: 595px;

    @media screen and (max-height: 900px) {
        overflow: auto;
    }

    &__row-color {
        display: inline-block;
        border-radius: 20%;
        width: 25px;
        height: 25px;
        margin-bottom: -5px;
    }

    &__row-addon {
        font-size: 0.8rem;
        font-weight: 600;
        color: #515151;
        position: relative;
    }

    &__title-icon {
        position: absolute;
        color: #b2b2b2;
        margin-left: 2px;
        margin-top: -2px;
        font-size: 12px;
    }

    &__table-row td {
        padding: 10px 16px;
    }
}

::v-deep .ant-table {
    overflow: auto;
}

::v-deep .ant-table-content {
    min-width: max-content;
}

::v-deep .ant-table-body {
    height: 525px;
}

::v-deep .ant-table-content {
    overflow-y: auto;
}

::v-deep .ant-pagination {
    margin: 16px !important;
}

::v-deep .color-module-table__table-row td {
    padding: 6px 16px;
}
