@import "@/assets/scss/style.scss";

.ant-calendar-picker,
.ant-select {
    display: block;
    width: 100%;
}
label {
    display: block;
    color: $grey-8;
    padding-bottom: 3px;
    .required {
        color: $red-6;
    }
}
.ant-row:not(:first-child) {
    margin-top: 15px;
}
.ant-col {
    &:not(:first-child) {
        padding-left: 5px;
    }
    &:not(:last-child) {
        padding-right: 5px;
    }
}
