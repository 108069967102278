@import "@/assets/scss/style.scss";

.c-pricelist-out-of-range-slot {
    display: flex;
    flex-direction: column;

    .ant-modal-footer {
        padding: 20px 16px;
    }
}
