@import "@/assets/scss/style.scss";

.client-information {
    display: flex;
    flex-direction: column;

    &__label-wrap {
        display: flex;
        align-items: center;
    }

    &__field {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 6px 0;

        &:first-child {
            flex-direction: column;
            align-items: flex-start;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__field-label {
        padding: 0 5px 0 10px;
        font-size: 1rem;

        &:first-child {
            padding: 0;
        }
    }

    &__field-value {
        font-weight: bold;
        font-size: 1rem;

        &:first-child {
            font-size: 0.7rem;
        }
    }
}

::v-deep .ant-select-selection-selected-value {
    white-space: break-spaces;
    word-break: break-word;
}
