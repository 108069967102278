@import "@/assets/scss/style.scss";

.c-add-product-row {
    padding-top: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.has-errors {
        .c-checklist__row,
        .c-add-product-row__row-number,
        .c-add-product-row__icon-wrapper {
            background-color: $pale-rose;
        }
    }

    &.has-warnings {
        .c-checklist__row,
        .c-add-product-row__row-number,
        .c-add-product-row__icon-wrapper {
            background-color: $pale-yellow;
        }
    }

    &.is-active {
        .c-checklist__row,
        .c-add-product-row__row-number,
        .c-add-product-row__icon-wrapper {
            background-color: $grey-4;
        }
    }

    &__pricelist-error {
        background: #fff1f0;
        border: 1px solid #ffa39e;
        border-radius: 2px;
        padding: 9px 16px;
        display: inline-block;
        margin-bottom: 4px;

        .anticon {
            margin-right: 10px;
        }

        span {
            font-size: 14px;
            line-height: 18px;
            color: #595959;
        }

        & > * {
            display: inline-block;
        }
    }

    &__error-actions {
        margin-left: 40px;

        .ant-btn {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__delete-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $grey-9;
        transition: opacity 0.2s ease-in-out;
        padding: 4px 6px;
        height: 100%;

        &:hover {
            opacity: 0.7;
        }
    }

    &__icon-wrapper {
        display: block;
        position: sticky;
        flex: 0 0 72px;
        z-index: 10;
        height: auto;
        align-self: stretch;
        left: 40px;
        background-color: $white-two;
        transition: background-color 0.2s ease-in-out;
    }

    &__row-number {
        display: flex;
        flex: 0 0 40px;
        position: sticky;
        z-index: 10;
        padding-left: 16px;
        left: 0;
        height: auto;
        align-self: stretch;
        background-color: $white-two;
        color: $grey-7;
        align-items: center;
        transition: background-color 0.2s ease-in-out;
    }

    &__empty-message {
        margin-bottom: 0;
    }
}

.c-add-material-slot__row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .label {
        display: inline-block;
        height: 32px;
        width: 100%;
        background-color: #f3f4f5;
        margin-bottom: 4px;
        margin-top: 4px;
        color: #8c8c8c;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        white-space: nowrap;
        border-radius: 3px;
        padding: 6px;
        height: 40px;
    }
}

.c-add-material-slot__wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: 16px;

    ::v-deep button {
        width: fit-content;
        margin: 5px 0;
    }

    &.dropdown {
        margin-left: 0;
    }
}

.dropdown {
    display: flex;
    flex-direction: column;
    min-width: 170px;
}
