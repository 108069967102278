@import "@/assets/scss/style.scss";

.color-module {
    &__tabs {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 2rem;
        width: 100%;
    }

    &__title {
        font-size: 1.2rem;
        font-weight: 600;
        color: #333;
        position: relative;
        cursor: pointer;
        transition: color 0.3s;
    }

    &__title-icon {
        position: absolute;
        color: #b2b2b2;
        margin-left: 3px;
        margin-top: -5px;
        font-size: 15px;
    }
}

.title--active {
    color: #1890ff;
}
