@import "@/assets/scss/style.scss";

.c-checklist {
    &__wrapper {
        padding: 24px 0 100px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        &.nopad {
            padding: 0px;
        }
    }

    &__close-button {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 100;
        top: 13px;
        right: 4rem;
        border-width: 2px;
        border-radius: 50% !important;

        &:hover {
            color: #ff2e2e;
            border-color: #ff2e2e;
        }
    }

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 40%;

    @media screen and (max-width: 1700px) {
        max-width: 60%;
    }
    @media screen and (max-width: 900px) {
        max-width: 95%;
    }
    &.wide-checklist {
        max-width: 100%;
    }
}

.footer-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: auto;
    margin-bottom: 36px;
    padding: 0 16px;
    width: 100%;
    max-width: 40%;
    position: relative;
    margin-top: 36px;

    @media screen and (max-width: 1700px) {
        max-width: 60%;
    }
    @media screen and (max-width: 900px) {
        max-width: 95%;
    }

    &.extend {
        min-height: fit-content;
    }

    ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        text-align: left;
        list-style: none;
        width: 100%;
        max-height: 210px;
        overflow-y: scroll;

        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: 4px 0;
            color: #ff0000;

            i {
                margin-right: 6px;
            }
        }
    }

    .order-popup-buttons-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }
}

.no-errors-button {
    margin-right: 10px;
    background-color: #52c41a;
    color: white;
}

.errors-button {
    margin-right: 10px;
    color: white;
    background-color: #ff0000;
}

.no-errors-button:hover {
    text-decoration: none;
    cursor: not-allowed;
    background-color: #52c41a !important;
    color: white !important;
}

.errors-button:hover {
    text-decoration: none;
    cursor: cursor;
    background-color: #ff1c1c !important;
    color: white !important;
}

.close-errors-btn {
    position: absolute;
    top: 16px;
    right: 32px;
}

.configurator-buttons__inner-wrap {
    overflow-x: auto;
    width: 100%;
}

@media screen and (max-width: 900px) {
    .margin-top30 {
        margin-top: 30px;
    }
}

@media screen and (max-width: 500px) {
    .configurator-buttons {
        flex-direction: column;
        gap: 20px;
    }
}
