@import "@/assets/scss/style.scss";

.c-activities-screen {
  padding: 24px 24px;
  background-color: white;

  &__button-group {
    margin-bottom: 10px;
  }

  &__number-of-results {
    color: #8c8c8c;
  }

  &__actions {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 16px;
  }
}
