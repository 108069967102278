@import "@/assets/scss/style.scss";

.container {
    width: 100%;
    display: flex;
    flex-direction: row;

    .ant-btn {
        width: 100%;
        padding: 9px 16px;
        height: 40px;

        background: #ffffff;
        box-shadow: inset 1px 0 0 #d9d9d9, inset 0px -1px 0px #d9d9d9, inset 0px 1px 0px #d9d9d9;
        border-radius: 2px 0 0 2px;

        color: $grey-8;
        font-family: $font-medium;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }

    .selected {
        border: 1px solid $blue-6 !important;
        color: $blue-6 !important;
    }
}
