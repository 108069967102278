@import "@/assets/scss/style.scss";

.c-additional-pdf-options {
    &__subtitle {
        margin-bottom: 8px;
    }

    &__changes {
        margin-left: 4px;
    }

    &__row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .c-additional-pdf-options__group-wrapper {
            border-bottom: none;
            margin-bottom: 0;
            padding-bottom: 0;
            flex: 1 0 50%;

            &:first-child {
                border-right: 1px solid $grey-4;
            }

            &:last-child {
                padding-left: 24px;
            }
        }
    }

    &__group {

        display: flex;
        flex-direction: column;

        &-wrapper {
            padding-bottom: 8px;
            margin-bottom: 8px;
            border-bottom: 1px solid $grey-4;
        }

    }
}
