@import "@/assets/scss/style.scss";

.cls-1 {
    fill: #fff;
}

.cls-1,
.cls-2 {
    fill-rule: evenodd;
}

.cls-3 {
    fill: #134395;
}

.cls-4,
.cls-2 {
    fill: #484b4f;
}

.cls-5 {
    fill: url(#linear-gradient);
}
