@import "@/assets/scss/style.scss";

.client-logo-attachments {
    padding: 0 5px;
}

.client-attachment-wrap {
    border: 2px solid #eaeaea;
    border-radius: 5px;

    &:first-child {
        margin-right: 20px;
    }
}

.label {
    margin-bottom: 1rem;
}
