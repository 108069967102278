@import "@/assets/scss/style.scss";

.co-autocomplete-group-input {
    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $mid-blue;

        &-icon {
            margin-right: 16px;
            font-size: 22px;
        }
    }
}
