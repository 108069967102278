@import "@/assets/scss/style.scss";

.co-price-module {
    width: 100%;
    max-width: 40%;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 15;
    height: 45px;
    overflow: visible;
    position: fixed;
    bottom: 20px;

    &.isOverlappingButton {
        position: static;
    }

    &:hover:not(.is-loading) {
        //height: auto;
        box-shadow: 0 0 16px 0 rgba(38, 88, 165, 0.32);

        .co-price-module__row {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            border-left: 1px solid $black;
            border-right: 1px solid $black;

            &:last-child {
                animation: none;
                border-top-right-radius: 0;
                border-top-left-radius: 0;
                border-bottom: 1px solid $black;
            }

            &:first-child {
                border-top: 1px solid $black;
            }
        }
    }

    &__row {
        display: flex;
        padding: 3px 32px;
        justify-content: space-between;
        opacity: 0;
        align-items: center;
        visibility: hidden;
        transform: translateY(15px);
        transition: 0.3s ease-out;
        background-color: $white-two;
        color: $dark;
        flex-wrap: wrap;

        &__switch-container {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-bottom: 12px;
        }

        &--bottom-padded {
            padding-bottom: 12px;
        }

        &--is-fixed-price {
            color: $ant-red;
            span {
                font-weight: bold;
            }
        }

        &:first-child {
            padding-top: 12px;
            border-top-left-radius: 18px;
            border-top-right-radius: 18px;
        }

        &:last-child {
            color: $white-two;
            background-color: $mid-blue;
            cursor: pointer;
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
            padding-bottom: 12px;
            padding-top: 12px;
            border-radius: 18px;
            animation: pulse 3s infinite;
            min-height: 45px;
            max-width: 100%;
        }
    }

    &__label {
        padding-right: 20px;
        max-width: 85%;
        word-break: break-word;
    }

    &__value {
        &.is-accented {
            font-weight: 600;
            color: $mid-blue;
        }
    }

    &__category-details {
        width: 100%;
        display: flex;
        padding-left: 24px;
        justify-content: space-between;
        margin-top: 4px;
    }
}
@media (max-width: 900px) {
    .co-price-module {
        max-width: 50%;
        right: 20%;
        &__label {
            padding-right: 0px;
        }
    }
}
@media (max-width: 700px) {
    .co-price-module {
        max-width: 70%;
        right: 11%;
    }
}


@media (max-width: 500px) {
    .co-price-module {
        max-width: 75%;
        right: 4%;

        &__row {
            padding: 3px 10px;
            &:first-child {
                padding-top: 5px;
            }

            &:last-child {
                padding-bottom: 3px;
                padding-top: 3px;
            }
        }
    }
}
@media (max-width: 320px) {
    .co-price-module {
        max-width: 77%;
        right: 1%;

        &__row {
            padding: 3px 10px;
            &:first-child {
                padding-top: 5px;
            }

            &:last-child {
                padding-bottom: 3px;
                padding-top: 3px;
                overflow:scroll;
            }
        }
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(38, 88, 165, 0.32);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(38, 88, 165, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(38, 88, 165, 0);
    }
}
