@import "@/assets/scss/style.scss";

.co-add-product-popup-slot {
    &__popup-wrapper {
        max-height: 70vh;
        overflow-y: auto;
    }

    &__popup-warning {
        @include flex-center;
        font-size: 1.2rem;
        font-weight: bold;
        color: red;
    }

    &__confirm-button-disabled {
        margin-left: 4px;
    }
}
