@import "@/assets/scss/style.scss";

section {
    margin-bottom: 16px;
}

.section-name {
    color: black;
    margin-bottom: 8px;
}

.row {
    color: #595959;
}

.exclamation-circle {
    font-size: 22px;
    color: #ffb200;
    margin-right: 16px;
    line-height: 18px;
}

h5 {
    display: flex;
    align-items: center;
}

.dropdown {
    width: 100%;
}

.deleteMessage {
    margin-bottom: 8px;
}

.assignMessage {
    margin-bottom: 16px;
}

.projectList {
    margin-bottom: 12px;
    height: 300px;
    overflow-y: auto;
}

.selectLabel {
    color: #8c8c8c;
}

.ant-modal-body.pdf-preview-body {
    height: 70vh;
    overflow-y: hidden;
    scrollbar-width: thin;
    background-color: $grey-three;
}

.title-header-container {
    display: flex;
    justify-content: space-around;
}

.header-actions {
    display: flex;
    justify-content: flex-end;
}

.title-header-container > * {
    flex: 1;
}

.header-actions > * + button {
    margin-left: 8px;
}

.pdf-preview-body::-webkit-scrollbar {
    width: 16px;
}

.pdf-preview-body::-webkit-scrollbar-thumb {
    background-color: $grey-1;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: auto;

    &.extend {
        min-height: fit-content;
    }

    ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        text-align: left;
        list-style: none;
        width: 100%;
        max-height: 210px;
        overflow-y: scroll;

        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            padding: 4px 0;
            color: #ff0000;

            i {
                margin-right: 6px;
            }
        }
    }

    .order-popup-buttons-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}

.no-errors-button {
    margin-right: 10px;
    background-color: #52c41a;
    color: white;
}

.errors-button {
    margin-right: 10px;
    color: white;
    background-color: #ff0000;
}

.no-errors-button:hover {
    text-decoration: none;
    cursor: not-allowed;
    background-color: #52c41a !important;
    color: white !important;
}

.errors-button:hover {
    text-decoration: none;
    cursor: cursor;
    background-color: #ff1c1c !important;
    color: white !important;
}

.ant-modal-footer {
    position: absolute;
    background: white;
    left: 0;
    right: 0;
    bottom: 0;

    .close-errors-btn {
        position: absolute;
        top: 16px;
        right: 16px;
    }
}
.pdf-app.light {
    --pdf-toolbar-color: grey;
    --pdf-app-background-color: $grey-three;
    --pdf-sidebar-content-color: $grey-three;
    --pdf-toolbar-sidebar-color: $grey-three;
}
