@import "@/assets/scss/style.scss";

section {
    margin-bottom: 16px;
}

.section-name {
    color: black;
    margin-bottom: 8px;
}

.row {
    color: #595959;
}

.exclamation-circle {
    font-size: 22px;
    color: #ffb200;
    margin-right: 16px;
    line-height: 18px;
}

h5 {
    display: flex;
    align-items: center;
}

.tabs {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    margin-top: 16px;

    li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        font-weight: 500;
        font-size: 15px;
        height: 48px;
        position: relative;
        cursor: pointer;

        &.active {
            color: #1890ff;
            font-weight: 500;

            &::before {
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 100%;
                border-top: 2px solid #1890ff;
                border-radius: 4px 4px 0 0;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                content: '';
                pointer-events: none;
            }
        }
    }
}

.recurring-wrapper {
    display: flex;
    flex-direction: column;

    .inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 64px;
    }

    .date-wrap {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        label {
            margin-bottom: 12px;
            font-weight: 600;
            font-size: 15px;
        }

        .mx-datepicker {
            width: 100%;
        }
    }
}

.select-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    span {
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 15px;
    }
}

.ant-input-number {
    width: 100% !important;
}

.repeat-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.radio-wrap {
    display: flex;
    flex-display: row;
    margin-top: 16px;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 12px;
        margin-right: 12px;

        input {
            margin-right: 8px;
        }

        label {
            margin-bottom: 0px;
            font-weight: 600;
            font-size: 15px;
        }
    }
}

.main-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
}

.ant-modal-body {
    max-height: 500px !important;
    overflow-y: scroll !important;
}

::v-deep .ant-input-group-addon {
    display: none;
}
