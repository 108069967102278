@import "@/assets/scss/style.scss";

.o-section {
    &__vertical-navigation {
        display: flex;
        flex-direction: column;
        background: $roltek-blue !important;
        min-height: 100vh;
        width: auto;
        padding: 20px 0;
        border: none;

        @media (hover: none) {
            padding-bottom: 60px;
        }

        .ant-menu-item {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 25px 0;
            padding-left: 35px;
            margin: 0 !important;
            color: rgba(255, 255, 255, 0.5);

            &:active {
                background-color: inherit;
            }

            &-selected {
                background-color: transparent !important;
                border-left: 4px solid #fff;
                color: #fff;

                .navigation-item {
                    margin-left: -4px;
                }

                .menu-item-color {
                    color: #fff;
                }

                &::after {
                    border-right: none;
                }
            }

            &:not(.o-section__user-group-picker) :hover {
                background-color: transparent !important;
                color: #fff;

                .navigation-item {
                    margin-left: -4px;
                }

                .menu-item-color {
                    color: #fff;
                }

                &::after {
                    border-right: none;
                }
            }

            &.is-hidden {
                display: none;
            }

            .menu-item-icon {
                display: flex;
                font-size: 20px;
            }
        }

        .logo {
            order: -3;
            margin-top: 20px;
            padding-left: 25px;
        }

        .o-section__user-group-picker {
            order: -2;
            padding: 25px;
            margin: 30px 0 25px 0 !important;
        }

        .notifications {
            margin-top: auto !important;

            & > div:first-child {
                width: 100%;
            }

            .notifications__label {
                width: 100%;
            }

            div {
                @include flex-center;
                position: relative;
            }

            .notifications__icon-wrap {
                position: relative;
            }

            .notifications-count {
                position: absolute;
                left: 10px;
                top: -5px;
                width: 14px;
                height: 14px;
                background-color: #ff5151;
                border-radius: 50%;
                color: #fff;
                font-size: 11px;
                font-weight: bold;
            }
        }

        .menu-item__button-group {
            order: -1;
        }

        .projects {
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: $roltek-blue-6;
                opacity: 0.7;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .clients {
            position: relative;

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: $roltek-blue-6;
                opacity: 0.7;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .navigation-item {
            display: flex;
            align-items: center;
        }
    }

    .ant-menu-inline-collapsed {
        .ant-menu-item {
            padding: 25px 0 !important;
            justify-content: center;
            transition: all 0.2s ease;
        }

        & .ant-icon {
            font-size: 20px;
        }

        .menu-item-label {
            opacity: 0;
            transition: none;
        }

        .logo {
            // padding: 0;
        }

        .o-section__user-group-picker {
            margin-left: auto;
            margin-right: auto;

            .user-group-select__current {
                justify-content: center;
                width: 36px;
            }
        }

        .notifications__label {
            max-width: 0;
        }
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-transform: uppercase;
        max-width: 200px;
        cursor: pointer;

        svg {
            display: block;
            width: 100%;
            height: 50px;
        }
    }

    .menu-item-color {
        color: rgba(255, 255, 255, 0.5);
    }
}

.ant-popover {
    width: 100%;
}
