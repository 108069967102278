@import "@/assets/scss/style.scss";

.co-app-table {
    flex: 1 0 100%;
    overflow-x: auto;
    position: relative;

    .ant-table-title {
        padding: 16px;
    }

    .ant-table {
        border-style: solid;
        border-width: 1px;
        border-color: $grey-4;
        border-radius: 10px;
        overflow: auto;
    }

    .ant-table-pagination.ant-pagination {
        float: left;
    }

    &__dropdown {
        width: 100%;
    }

    th[key='completed'] {
        .ant-table-column-title {
            display: block !important;
            text-overflow: ellipsis;
            overflow: auto;
            white-space: nowrap;
        }
    }

    .ant-table-thead {
        & > tr {
            & > th {
                color: $grey-7;
                padding: 21px 16px;
                background: $grey-2;
                font-size: 14px;
                @extend .u-b3;
            }
        }
    }

    .ant-table-body {
        max-height: 66vh;
        overflow-y: auto;
    }

    .ant-table-tbody {
        & > tr {
            & > td {
                background: $white-two;
                cursor: pointer;
                font-size: 14px;
                font-weight: 400;

                @extend .u-b3-;

                &.has-badge {
                    @extend .u-b2-;

                    span {
                        // background: $light-periwinkle;
                        // color: $mid-blue;
                        padding: 3px 15px;
                        // border-radius: 15px;
                    }
                }

                &.has-ellipsis {
                    text-overflow: ellipsis;
                    overflow: auto;
                    white-space: nowrap;
                }

                &.is-narrow {
                    width: 100px;
                }

                &.break-none {
                    white-space: nowrap;
                }

                &.break-all {
                    word-break: break-all;
                }

                .client-information {
                    font-size: 12px;
                    color: $grey-8;
                }

                .status-switcher {
                    width: 100%;
                }
            }
        }
    }

    .message-row {
        display: flex;
        justify-content: space-between;

        .ant-btn {
            display: inline;
            margin-bottom: auto;
            margin-top: auto;
        }
    }

    .ant-table-expanded-row td {
        p {
            margin-bottom: 5px;
            margin-left: 10px;
        }

        p:last-child {
            margin-bottom: 0px;
        }
    }
}

.row--warning {
    td {
        background-color: #ffacac !important;
    }
}

.row--warning:hover td {
    background-color: #ff8585 !important;
}
