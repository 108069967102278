@import "@/assets/scss/style.scss";

.phone-number-input {
    width: 100%;
    ::v-deep .country-selector__input {
        height: 40px !important;
        min-height: 40px !important;
    }

    ::v-deep input {
        border: none;
    }

    ::v-deep .country-selector__toggle {
        top: calc(50% - 13px);
    }

    ::v-deep .country-selector__label {
        display: none;
    }

    ::v-deep .country-selector__input {
        padding-top: 0 !important;
    }

    ::v-deep .country-selector__country-flag {
        top: 14px !important;
    }

    ::v-deep .input-tel__input {
        height: 40px !important;
        min-height: 40px !important;
        border-left: none !important;
    }

    ::v-deep .country-selector.sm {
        height: 40px !important;
        min-height: 40px !important;
    }

    ::v-deep .input-tel.sm {
        height: 40px !important;
        min-height: 40px !important;
    }

    ::v-deep .is-focused input {
        border-color: $blue-6 !important;
        border-right-width: 1px !important;
        outline: 0 !important;
        -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
        box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
    }
}

::v-deep .red-border {
    border-color: red;
}
