@import "@/assets/scss/style.scss";

section {
    margin-bottom: 16px;
    z-index: 1000;
}

.section-name {
    color: black;
    margin-bottom: 8px;
}

.row {
    color: #595959;
}

.exclamation-circle {
    font-size: 22px;
    color: #ffb200;
    margin-right: 16px;
    line-height: 18px;
}

h5 {
    display: flex;
    align-items: center;
}

footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;

    button {
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.border-lightgrey-padding {
    border: 1px solid lightgray;
    padding: 10px;
}
