@import "@/assets/scss/style.scss";

.cls-1, .cls-2 {
  fill: #fff;
}

.cls-3, .cls-4 {
  fill-rule: evenodd;
}

.cls-3, .cls-5 {
  fill: #010101;
}

.cls-6 {
  fill: #2a327f;
}

.cls-2 {
  stroke: #010101;
  stroke-miterlimit: 10;
  stroke-width: .05px;
}

.cls-7 {
  fill: none;
}

.cls-8 {
  fill: #2390cf;
}

.cls-9 {
  fill: #e52521;
}

.cls-10, .cls-4 {
  fill: #f7db18;
}
