@import "@/assets/scss/style.scss";

.country-flag-container {
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    background-size: 30px;
    position: absolute;
    z-index: 100;
    top: 34px;
    left: 20px;
}

.responsive-row {
    &>* {
        min-width: fit-content;
    }

    .select-country-wrapper {
        @media (max-width: 1450px) {
            width: 100%;
        }
    }
}
