@import "@/assets/scss/style.scss";

.img-container {
    overflow: hidden;
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.attachment-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
