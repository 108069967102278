@import "@/assets/scss/style.scss";

.c-project-table-wrapper {
    display: flex;
    flex-wrap: nowrap;
    min-height: 450px;
    position: relative;
    padding-left: 0;
    margin-top: 8px;
    transition: padding-right 0.3s ease-in-out;

    &.are-filters-visible {
        padding-right: 275px;

        &::v-deep {
            th[key='completed'] {
                .ant-table-column-title {
                    max-width: 30px;
                }
            }
        }
    }
}

.slide-fade-enter-active {
    transition: all 0.2s ease;
}

.slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(-10px);
    opacity: 0;
}
