@import "@/assets/scss/style.scss";

section {
    margin-bottom: 16px;
}

.section-name {
    color: black;
    margin-bottom: 8px;
}

.row {
    color: #595959;
}

.ant-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;

    .exclamation-circle {
        font-size: 22px;
        color: #ffb200;
        margin-right: 16px;
        line-height: 18px;
    }

    .complete-activity-btn {
        margin-right: 2rem;
        color: $grey-8;
        border: 1px solid $grey-8;
        padding: 0 15px;
        justify-content: center;
        align-items: center;
        justify-content: flex;
        height: 2rem;

        i {
            margin-right: 8px;
            margin-top: 5px;
        }
    }

    .completed {
        color: white;
        border-color: $green-5;
        background-color: $green-5 !important;
    }
}

h5 {
    display: flex;
    align-items: center;
}

.tabs {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0;
    margin-top: 16px;

    li {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        font-weight: 500;
        font-size: 15px;
        height: 48px;
        position: relative;
        cursor: pointer;

        &.active {
            color: #1890ff;
            font-weight: 500;

            &::before {
                position: absolute;
                bottom: 0px;
                left: 0;
                width: 100%;
                border-top: 2px solid #1890ff;
                border-radius: 4px 4px 0 0;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                content: '';
                pointer-events: none;
            }
        }
    }
}

.recurring-wrapper {
    display: flex;
    flex-direction: column;

    .inner {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .date-wrap {
        display: flex;
        flex-direction: column;

        label {
            margin-bottom: 12px;
            font-weight: 600;
            font-size: 15px;
        }

        .mx-datepicker {
            width: 100%;
        }
    }

    .new-project__wrap {
        margin-top: 3.157rem;
    }
}

.select-wrapper,
.check-mark__wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    span {
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 15px;
    }
}

.check-mark__wrapper {
    ::v-deep .ant-checkbox-inner {
        height: 25px;
        width: 25px;

        &::after {
            width: 7px;
            height: 13px;
        }
    }

    .check-box__title {
        margin-bottom: 14px;
        font-weight: 600;
        font-size: 15px;
    }
}

.ant-input-number {
    width: 100% !important;
}

.repeat-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.radio-wrap {
    display: flex;
    flex-display: row;
    margin-top: 16px;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 12px;
        margin-right: 12px;

        input {
            margin-right: 8px;
        }

        label {
            margin-bottom: 0px;
            font-weight: 600;
            font-size: 15px;
        }
    }
}

.main-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
}

.label-hover {
    cursor: pointer;
}
.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-modal-title {
    @include flex-center;
}
