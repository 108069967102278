@import "@/assets/scss/style.scss";

.cls-1 {
        fill: #fff;
      }

      .cls-2 {
        fill: #f4e600;
      }

      .cls-3 {
        fill: #2b4b9b;
      }

      .cls-4, .cls-5 {
        fill: none;
      }

      .cls-5 {
        clip-path: url(#clippath);
      }

      .cls-6 {
        fill: #e52521;
      }
