@import "@/assets/scss/style.scss";

.c-checklist {
    &__segment {
        border: 1px solid #eff0f5;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 12px;
        margin: 24px 0;
    }

    &__segment-title {
        color: $dark;
        padding-bottom: 24px;
    }
}

.light-blue {
    background: $very-light-blue;
}

.blue {
    background: $roltek-blue;
    color: $white;
}

.configurator-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;

    label {
        font-family: 'Roboto-Medium', 'Arial', sans-serif;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
    }

    > div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
    }
}

.c-add-material-slot__row {
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
}

.c-add-material-slot__wrapper.dropdown {
    display: flex;
    flex-direction: column;
    padding: 6px;

    .c-add-material-slot__select {
        width: 200px;
        margin-top: 12px;
    }
}

.c-add-material-slot__label {
    margin-right: 8px;
}

@media (max-width: 900px) {
    .c-checklist {
        &__segment {
            padding: 10px;
            margin: 24px 0;
            overflow: auto;
        }
    }
    .configurator-buttons {
        align-items: left;
        margin-top: 1rem;
        padding: 0 8px;

        > div {
            gap: 5px;
        }
    }
}

@media screen and (max-width: 500px) {
    .configurator-buttons {
        flex-direction: column;
        gap: 20px;
    }
}
