@import "@/assets/scss/style.scss";

.notification-content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 25px;
    margin-bottom: 5px;
    min-width: 100%;
    min-height: 75px;
    background-color: #fff;
    box-shadow: rgba(83, 81, 81, 0.2) 0px 2px 8px 0px;
}

.notification-content__text-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    .notification-content__title {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: bold;
        word-break: break-word;
        white-space: normal;
    }

    .notification-content__description {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        word-break: break-word;
        white-space: normal;
    }
}

.notification-content__icon-wrap {
    font-size: 24px;
    margin: -3px 20px 0 0;
}
