@import "@/assets/scss/style.scss";

.c-checklist {
    &__row {
        display: flex;
        margin-bottom: 16px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        max-width: 100%;
        background-color: $white-two;
        transition: background-color 0.2s ease-in-out;

        @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
        }

        &--boolean {
            .c-checklist__row-value {
                max-width: 40px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.is-alternative {
            width: 100%;
        }

        &.is-alternative,
        &.is-multiposition {
            flex-direction: column;
            margin-bottom: 0;
            justify-content: flex-start;
            padding: 4px 0;

            .c-checklist {
                &__row-label {
                    display: none;
                    padding-bottom: 6px;
                }

                &__row-value {
                    width: 100%;
                }
            }

            &.c-checklist__row--boolean {
                flex: 0 0 52px;
            }
        }

        &.is-multiposition {
            flex: 0 0 150px;
            max-width: 150px;
            width: fit-content;
            background-color: $white-two;
            padding-right: 8px;
            background-clip: padding-box;
            align-self: center;
            justify-self: center;

            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7) {
                max-width: 88px;
                position: sticky;
                z-index: 5;
            }

            &:nth-child(3) {
                max-width: none;
                left: 112px;
            }

            &:nth-child(4) {
                left: 262px;
            }

            &:nth-child(5) {
                left: 350px;
            }

            &:nth-child(6) {
                left: 438px;
            }

            &:nth-child(7) {
                max-width: none;
                left: 526px;
            }

            &:last-child {
                margin-right: 0;
            }

            &.c-checklist__row--dropdown {
                max-width: 150px;
            }
        }

        @media screen and (max-width: 880px) {
            &.is-multiposition {
                &:nth-child(3) {
                    position: static;
                }

                &:nth-child(4) {
                    position: static;
                }

                &:nth-child(5) {
                    position: static;
                }

                &:nth-child(6) {
                    position: static;
                }
                &:nth-child(7) {
                    position: static;
                }
            }
        }
    }

    &__row-label {
        max-width: fit-content;
        white-space: nowrap;
        border-radius: 3px;
        padding: 6px;

        &.level-1 {
            background: $very-light-blue;
        }

        &.level-2 {
            background: $roltek-blue;
            color: $white !important;
        }

        &.is-clickable {
            cursor: pointer;
        }
    }

    &__label-details {
        margin-left: 2px;
    }

    &__row-value {
        max-width: 400px;
        width: 100%;

        &.is-disabled {
            opacity: 0.2;
            pointer-events: none;
        }
    }

    &__row-details {
        align-self: flex-end;
        margin-bottom: 8px;
    }

    &__field-wrapper {
        display: flex;
    }

    &__image--full-screen {
        display: flex;
        justify-content: flex-end;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        position: fixed;
        top: 0;
        left: 0;
        background-color: white;
        border-radius: 10px;
        z-index: 1;
        margin: 4rem;
        height: calc(100% - 8rem); /* subtract 4rem from top and bottom */
        width: calc(100% - 8rem);

        i {
            margin: 1rem;
            font-size: 30px;
            height: fit-content;
            cursor: pointer;
            color: grey;

            &:hover {
                color: #545454;
            }
        }
    }

    &__image-wrapper {
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        flex: 1 0 auto;
        position: relative;

        &--small {
            background-size: auto;
        }
    }

    &__modal-inner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 100%;

        p {
            margin-top: 16px;
            margin-bottom: 0;
        }

        &__resize-icon {
            position: absolute;
            font-size: 40px;
            top: 20px;
            right: 20px;
            cursor: pointer;
            transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
            color: $roltek-blue;
        }
    }
}

.warning {
    border-color: #ff4d4f;
}
