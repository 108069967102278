@import "@/assets/scss/style.scss";

.warnings-wrap {
    max-height: 80vh;
    overflow-y: auto;

    &__icon {
        font-size: 25px;
        color: rgb(228, 228, 68);
    }

    &__title-wrap {
        display: flex;
        align-items: center;
    }

    &__title {
        margin-left: 10px;
        color: #273549;
        font-family: 'Roboto-Regular', 'Arial', sans-serif;
        font-size: 18px;
    }

    &__inner {
        max-height: 70vh;
        overflow-y: auto;
    }

    &__warning-item {
        font-size: 1.1rem;
        margin-bottom: 0.2rem;
    }

    &__warning-item:last-child {
        margin: 0;
    }

    &__name {
        color: #434343;
        font-weight: bold;
    }

    &__product-name {
        font-weight: bold;
        font-size: 1.3rem;
        color: rgb(233, 81, 81);
        margin-bottom: 1rem;
        display: block;
    }

    &__item {
        margin-bottom: 1.5rem;
    }

    &__item:last-child {
        margin: 0;
    }
}
