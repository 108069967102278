@import "@/assets/scss/style.scss";

.color-module-request {
    margin-bottom: 2.5rem;
    width: 100%;

    &__form {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        width: 100%;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__form-item {
        margin: 0;
        width: 100%;
    }

    &__form-label {
        display: block;
        margin-bottom: 0.5rem;

        &.form-label--name {
            position: relative;
        }
    }

    &__title-icon {
        position: absolute;
        color: #b2b2b2;
        font-size: 12px;
        top: -15px;
        left: 65px;
    }

    ::v-deep .ant-form-item-control {
        line-height: normal;
    }
}

.error {
    border-color: red;
}
