@import "@/assets/scss/style.scss";

.o-section {
    min-height: 100vh;
    height: 100vh;
    background: $light-periwinkle !important;

    &.logoHidden {
        .o-section__main-section {
            background-color: $intro-background !important;
        }
    }

    &__sider {
        background: $roltek-blue !important;
        min-height: 100vh !important;
        z-index: 100;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &__sider::-webkit-scrollbar-track {
        border-radius: 7px;
        background-color: transparent;
    }

    &__sider::-webkit-scrollbar {
        width: 8px;
        background-color: transparent;
    }

    &__sider::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #214d90;
    }

    &__main-section:not(.newProject) {
        background: #fff !important;
    }

    &__breadcrumbs {
        margin: 16px 0 !important;
    }

    &__content {
        min-height: 280px;
    }
}
