@import "@/assets/scss/style.scss";

.c-add-product-row {
    margin-bottom: 4px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 10;
    background-color: $white-two;

    &__header {
        background-color: $multiposition-background;
        padding: 20px 0;
    }

    &__title-icon {
        position: absolute;
        color: #b2b2b2;
        margin-left: 2px;
        margin-top: -2px;
        font-size: 12px;
    }
}

.c-checklist {
    &__row {
        display: flex;
        margin-bottom: 16px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        max-width: 100%;

        &--boolean {
            .c-checklist__row-value {
                max-width: 40px;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &.is-alternative {
            width: 100%;
        }

        &.is-alternative,
        &.is-multiposition {
            flex-direction: column;
            margin-bottom: 0;
            justify-content: flex-start;

            .c-checklist {
                &__row-label {
                    display: inline-block;
                    height: 32px;
                    width: 100%;
                    background-color: $multiposition-background;
                    margin-bottom: 4px;
                    margin-top: 4px;
                    color: $grey-7;
                    max-width: fit-content;
                    white-space: nowrap;
                    border-radius: 3px;
                    padding: 6px;
                    height: 40px;

                    &.level-1 {
                        background: $very-light-blue;
                    }

                    &.level-2 {
                        background: $roltek-blue;
                        color: $white !important;
                    }

                    &.is-clickable {
                        cursor: pointer;
                    }

                    span {
                        overflow: hidden;
                        white-space: normal;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        word-break: break-all;
                        visibility: visible;
                    }
                }

                &__row-value {
                    width: 100%;
                    // max-width: initial;
                    // min-width: initial;
                }
            }

            &.c-checklist__row--boolean {
                flex: 0 0 52px;
            }

            &.is-first {
                .c-checklist__row-label {
                    display: inline-block;
                    text-align: center;
                    white-space: initial;
                    height: 20px;
                }
            }
        }

        &.is-multiposition {
            flex: 0 0 150px;
            max-width: 150px;
            width: fit-content;
            background-color: $multiposition-background;
            padding-right: 8px;
            background-clip: padding-box;
            justify-content: center;
            align-items: center;

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
                max-width: 88px;
                position: sticky;
                z-index: 5;
            }

            &:nth-child(2) {
                max-width: none;
                left: 112px;
            }

            &:nth-child(3) {
                left: 262px;
            }

            &:nth-child(4) {
                left: 350px;
            }

            &:nth-child(5) {
                left: 438px;
            }

            &:nth-child(6) {
                max-width: none;
                left: 526px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        @media screen and (max-width: 880px) {
            &.is-multiposition {
                &:nth-child(2) {
                    position: static;
                }

                &:nth-child(3) {
                    position: static;
                }

                &:nth-child(4) {
                    position: static;
                }

                &:nth-child(5) {
                    position: static;
                }

                &:nth-child(6) {
                    position: static;
                }
            }
        }
    }

    &__row-number-label {
        min-width: 112px;
        min-height: 80px;
        height: auto;
        display: block;
        position: sticky;
        left: 0;
        padding-left: 16px;
        content: '';
        z-index: 10;
        background-color: $multiposition-background;
        color: $grey-7;
        // margin-top: 10px;
        padding-top: 6px;

        .c-checklist__row-value {
            padding-top: 1rem;
        }
    }

    &__row-label {
        white-space: nowrap;

        &.is-clickable {
            cursor: pointer;
        }
    }

    &__label-details {
        margin-left: 2px;
    }

    &__row-value {
        max-width: 400px;
        width: 100%;

        &.is-disabled {
            opacity: 0.2;
            pointer-events: none;
        }
    }

    &__row-details {
        align-self: flex-end;
        margin-bottom: 8px;
    }

    &__field-wrapper {
        display: flex;
    }

    &__image-wrapper {
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        flex: 1 0 auto;

        &--small {
            background-size: auto;
        }
    }

    &__modal-inner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        height: 100%;

        p {
            margin-top: 16px;
            margin-bottom: 0;
        }
    }
}

.c-add-material-slot__row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .label {
        display: inline-block;
        height: 32px;
        width: 100%;
        background-color: #f3f4f5;
        margin-bottom: 4px;
        margin-top: 4px;
        color: #8c8c8c;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        white-space: nowrap;
        border-radius: 3px;
        padding: 6px;
        height: 40px;
    }
}

.c-add-material-slot__wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-left: 16px;

    ::v-deep button {
        width: fit-content;
        margin: 5px 0;
    }

    &.dropdown {
        margin-left: 0;
    }
}

.dropdown {
    display: flex;
    flex-direction: column;
    min-width: 170px;
}

.is-multiposition-header-frozen {
    position: sticky;
    top: 0;
    z-index: 100;
}
