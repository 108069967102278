@import "@/assets/scss/style.scss";

.c-dropdown {
    width: 100%;

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__wrapper {
        width: 100%;
    }

    &__color {
        display: inline-block;
        width: 40px;
        height: 20px;
        background-color: var(--bg-color);
        margin-right: 8px;
        border-radius: 7px;
        border: solid 1px #adc0da;
    }

    &__value {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    &__color-picker-icon {
        margin-left: 10px;
    }
}

.c-radio {
    display: flex;
    align-items: center;

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        min-width: 0;
    }
}

// .c-dropdown {
//     position: relative;
//     display: inline-block;
//     width: 100%;
//     border: 1px solid #d9d9d9;
//     border-radius: 2px;
//     background-color: #fff;
//     font-size: 14px;
//     color: #000;
//     cursor: pointer;
//     padding: 5.5px 12px;
//     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
//     transition: border-color 0.3s ease;

//     &__value {
//         width: 100%;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//         overflow: hidden;
//     }

//     &__color-picker-icon {
//         margin-left: 10px;
//     }
// }

// .c-dropdown:focus {
//     border-color: #1890ff;
//     outline: none;
//     box-shadow: 0 0 4px #1890ff;
// }

// /* Selected value and arrow */
// .c-dropdown__selected {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     color: #333;

//     & span:first-child {
//         width: 100%;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//     }
// }

// .c-dropdown__arrow {
//     font-size: 12px;
//     margin-left: 8px;
//     color: #bbb;
// }

// /* Dropdown menu */
// .c-dropdown__menu {
//     position: absolute;
//     top: calc(100% + 4px);
//     left: 0;
//     z-index: 1000;
//     background-color: #fff;
//     border: 1px solid #d9d9d9;
//     border-radius: 4px;
//     box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
//     max-height: 200px;
//     overflow-y: auto;
//     overflow-x: visible;
//     /* Full-width adjustment */
//     width: auto;
//     min-width: 100%;
// }

// /* Dropdown option */
// .c-dropdown__option {
//     padding: 8px 12px;
//     font-size: 14px;
//     color: #333;
//     cursor: pointer;
//     transition: background-color 0.2s ease;
// }

// .c-dropdown__option:hover {
//     background-color: #f5f5f5;
// }

// /* Content inside options */
// .c-dropdown__content {
//     display: flex;
//     flex-direction: row;
//     align-items: center;

//     & div:first-child {
//         @include flex-center;
//     }
// }

// .c-dropdown__color {
//     width: 12px;
//     height: 12px;
//     border-radius: 50%;
//     margin-right: 8px;
// }

// .c-dropdown__value {
//     display: inline-block;
//     width: 100%;
//     color: #4c4c4c;
//     white-space: nowrap;
// }

// /* Disabled state */
// .c-dropdown[tabindex='-1'] {
//     background-color: #f5f5f5;
//     cursor: not-allowed;
//     color: #bbb;
//     border-color: #d9d9d9;
// }

::v-deep .c-radio__button span:nth-child(2) {
    width: 100%;
    text-align: center;
}
