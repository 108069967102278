@import "@/assets/scss/style.scss";

.co-add-product-popup-slot {
    &__button {
        background-color: $blue-6;
    }
}

.overlay {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
    z-index: 1000000000;

    .prograss-bar__wrapper {
        width: 70%;
        margin-bottom: 10rem;
    }
}

::v-deep .ant-progress-bg {
    height: 12px !important;
}
