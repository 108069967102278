@import "@/assets/scss/style.scss";

.section {
    display: flex;
    flex-direction: column;

    &__add-email {
        margin-right: 4px;
    }

    .invalid-mail {
        border-color: #ff0000;
    }

    &__input-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 20px;
        flex-wrap: wrap;

        .required {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            /* Dust Red / red-6 */

            color: $red-6;
        }

        p {
            font-family: $font-regular;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */
            margin-bottom: 4px;

            /* Grey / grey-8 */

            color: $grey-8;
        }

        &__warning {
            color: red;
        }

        &__emails {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 49.2%;
            margin-right: auto;

            &__inner {
                position: relative;
                display: flex;
                flex-direction: row;
                gap: 4px;
                height: 40px;

                .primary-selected {
                    background: $green-1;
                    border: 1px solid $green-3;
                    color: $green-5;
                }

                input {
                    height: 40px;
                }

                .indented {
                    text-indent: 70px;
                }

                .ant-btn {
                    height: 40px;
                }

                &__primary {
                    height: 22px !important;
                    width: 58px;
                    position: absolute;
                    top: 9px;
                    left: 12px;
                    z-index: 1;
                    font-size: 12px;
                    padding: 0;
                }
            }
        }

        &__phones {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 49.2%;

            &__inner {
                display: flex;
                flex-direction: row;
                gap: 4px;
                border: 1px solid #d9d9d9;

                .ant-btn {
                    height: 40px;
                }
            }
        }
    }
}
.red-border {
    border-color: red;
}
