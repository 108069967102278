@import "@/assets/scss/style.scss";

.color-module {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    height: auto;
    width: 60vw;
    min-height: 80vh;
    max-height: 85vh;
    flex-wrap: nowrap;

    // add breakpoints for responsive design
    @media (max-width: 1900px) {
        width: 65vw;
    }

    @media (max-width: 1600px) {
        width: 70vw;
    }

    @media (max-width: 1400px) {
        width: 75vw;
    }

    @media (max-width: 1200px) {
        width: 80vw;
    }

    @media (max-width: 1000px) {
        width: 85vw;
    }

    @media (max-width: 800px) {
        width: 90vw;
        max-height: 90vh;
    }
}

.title--active {
    color: #1890ff;
}

::v-deep .color-module-popup {
    z-index: 1010;
}

::v-deep .ant-spin {
    width: 100%;
}

::v-deep .ant-table-placeholder {
    margin-top: -10rem;
}
