@import "@/assets/scss/style.scss";

.dimensions-wizard {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__segment {
        width: 100%;
    }
}
.margin {
    margin: 24px;
}
.center {
    text-align: center;
    & * {
        margin-left: auto;
        margin-right: auto;
    }
}
.image {
    display: block;
    max-width: 100%;
    max-height: 500px;
    border-radius: 4px;

    &.empty {
        width: 100px; // 50%;
        height: 100px;
        background-color: $grey-three;
    }
}
