@import "@/assets/scss/style.scss";

.header-section {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    .title {
        font-weight: bold;
        padding-right: 10px;
    }

    .border {
        flex-grow: 2;
        border-bottom: 1px dashed $grey-4;
        height: 1px;
    }
}

.client-checker {
    display: flex;

    span {
        font-weight: bold;
        margin-right: 10px;
    }
}

.form-section {
    margin-bottom: 40px;
}

.label {
    span {
        color: $ant-red;
    }

    color: $grey-8;
}
