@import "@/assets/scss/style.scss";

.co-home-upper-section {
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;
    margin-top: 16px;
    transition: padding-right 0.3s ease-in-out;
    &.are-filters-visible {
        padding-right: 275px;
    }

    &.sidebar-collapsed {
        padding-right: 0;
    }

    &__right {
        text-align: right;

        & > * {
            margin-right: 8px;
            height: 40px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
