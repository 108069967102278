@import "@/assets/scss/style.scss";

.c-contact-slot {
    display: flex;
    flex-direction: column;

    label {
        font-size: 16px;
        line-height: 22px;
        color: #000;
        margin-bottom: 4px;
    }

    &__textarea {
        margin-bottom: 10px;
    }

    .ant-modal-footer {
        padding: 20px 16px;
    }
}
