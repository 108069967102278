@import "@/assets/scss/style.scss";

.co-add-product-popup-slot {
    &__add-product-wrapper {
        height: 100%;
    }

    &__segment-selector {
        width: 100%;
        text-align: center;
        position: sticky;
        top: -22px;
        z-index: 10;
        background-color: $white-two;
        margin-top: 20px;

        &::v-deep {
            .ant-tabs-bar {
                margin-bottom: 0;
            }
        }
    }

    &__inner-wrap {
        overflow-x: auto;
    }

    &__combinations {
        padding: 20px;
        max-width: 90vw;
    }

    &__combination-wrap {
        overflow-y: auto;
        max-height: 65vh;
        padding-right: 20px;
    }

    &__combination {
        margin-bottom: 20px;
        @include flex-center;
        justify-content: flex-start;

        &:last-child {
            margin: 0;
        }
    }

    &__combination-buttons {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__combination-add-button {
        margin-top: 20px;
    }

    &__loading {
        position: absolute;
        top: 32px;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.6);

        .anticon {
            font-size: 40px;
        }
    }

    &__product-wrapper {
        max-height: calc(100vh - 367px);

        // @media (max-height: 900px) {
        //     max-height: 518px;
        // }
        // @media (max-height: 800px) {
        //     max-height: 418px;
        // }
    }
}

.light-blue {
    background: $very-light-blue;
}

.blue {
    background: $roltek-blue;
    color: $white;
}

.configurator-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 16px 0;
    padding: 0 20px;

    label {
        font-family: 'Roboto-Medium', 'Arial', sans-serif;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
    }

    > div {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;
    }
}
