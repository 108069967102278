@import "@/assets/scss/style.scss";


.cls-1 {
    fill: #fff;
}

.cls-1,
.cls-2,
.cls-3,
.cls-4 {
    fill-rule: evenodd;
}

.cls-5,
.cls-3,
.cls-4 {
    fill: #484b4f;
}

.cls-2 {
    fill: #134395;
    stroke: #fff;
    stroke-width: 7.85px;
}

.cls-2,
.cls-3 {
    stroke-miterlimit: 10;
}

.cls-6 {
    fill: url(#linear-gradient);
}

.cls-3 {
    stroke: #484b4f;
    stroke-width: 1.27px;
}
