@import "@/assets/scss/style.scss";

.combinations-wizard {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    .ant-modal-footer{
        @media (max-width: 900px) {
          position:fixed;
          bottom:0;

    }}

    &__segment {
        width: 100%;
        padding: 0.5rem;
    }

    &__title-wrap {
        margin-top: 0.5rem;
        @include flex-center;
        justify-content: space-around;

        & > * {
            width: 30%;
        }

        span {
            text-align: center;
        }
        @media (max-width: 700px) {
            span {
            text-align: left;
        }
        & > * {
            width: auto;
        }
        }
    }

    &__unit-selector {
        @include flex-center;
        padding-right: 10px;

        span {
            margin-right: 0.5rem;
            font-weight: bold;
            font-size: 1.2rem;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 1.2rem;
        color: rgb(51, 51, 51);
    }

    &__canvas-wrap {
        height: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 2rem;
    }

    &__canvas-selected {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
        overflow: hidden;
        gap: 2rem;

        & > div {
            @include flex-center;
        }
        @media (max-width: 700px) {
   /*          flex-direction:column; */
        }
    }

    &__selected-element-canvas {
        height: 100%;
        max-width: 70%;

        & > div:first-child {
            overflow: auto;
            height: fit-content;
            max-height: 100%;
        }
    }

    &__canvas-element {
        height: fit-content;
        width: fit-content;
        margin: 0 3rem 3rem 0;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
            background-color: #f8f8ff;
        }
    }

    &__product-wrap {
        display: flex;
        flex-direction: column;
        min-width: 30%;
    }

    &__product-fields {
        @include flex-center;
        gap: 1rem;
        margin-bottom: 0.5rem;
    }

    &__product-row {
        display: flex;
        align-items: center;
        max-width: 10rem;

        span {
            margin: 0 0.5rem 0 -1.2rem;
        }
    }

    &__product-labels {
        @include flex-center;
        margin-left: -6.8rem;

        span:first-child {
            margin-right: 8.3rem;
        }
    }

    &__product-label {
        font-weight: bold;
    }

    &__btn--disabled {
        margin-left: 4px;
    }

    &__back-button {
        @include flex-center;

        i {
            cursor: pointer;
            font-size: 2.5rem;
            color: rgb(24, 144, 255);
            transition: 0.3s ease;
        }

        i:hover {
            opacity: 0.6;
        }
        @media (max-width: 700px) {
            justify-content: left!important;
            margin-bottom:-8px;
        }
    }

    .element--selected {
        box-shadow: 0 0 10px #c0c0cc;
    }

    .v-enter-from {
        opacity: 0;
        translate: -100px 0;
    }
    .v-enter-to {
        opacity: 1;
        translate: 0 0;
    }
    .v-leave-from {
        opacity: 1;
        translate: 0 0;
    }
    .v-leave-to {
        opacity: 0;
        translate: 100px 0;
    }
}


