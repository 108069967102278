@import "@/assets/scss/style.scss";

.responsive-row {
    &>* {
        min-width: fit-content;
    }
}

.checkbox-container {
    width: 100%;
    margin-top: 21px;
    border: 1px solid $grey-1;
    padding: 8px 10px;
    margin-bottom: 1rem;

    .ant-checkbox-wrapper {
        margin-right: 10px;
    }
}

::v-deep .section__input-container__emails,
::v-deep .section__input-container__phones {
    @media (max-width: 630px) {
        width: 100%;
    }
}
