@import "@/assets/scss/style.scss";

.discounts-card {
    margin-top: 40px;
    width: 100%;
}
.discunt-table-heder {
    margin-top: 40px;
    margin-bottom: 10px;
}
.table-header-label {
    font-size: 12px;
    color: $grey-7;
}

.discount-wrapper {
    width: 100%;
    overflow-x: auto;
    padding: 24px;

    .inner-wrap {
        min-width: 1170px;
    }
}
