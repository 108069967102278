@import "@/assets/scss/style.scss";

.notifications {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 30rem;
    height: auto;
    max-height: 60vh;
    overflow-y: auto;

    &__header {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__top-bar {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgb(225, 218, 255);
        padding-bottom: 0.2rem;
    }

    &__body {
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        padding-right: 0.3rem;
        width: 100%;
        overflow-y: auto;
    }

    &__group {
        margin: 1rem 0;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__title {
        font-size: 1.3rem;
        font-weight: bold;
        color: rgb(57, 57, 57);
    }

    &__group-title {
        font-size: 0.9rem;
        font-weight: bold;
        color: rgb(57, 57, 57);
        // color: $roltek-blue;
        opacity: 0.8;
    }

    &__label {
        font-size: 0.8rem;
        font-weight: bold;
        opacity: 0.7;

        &--active {
            cursor: pointer;
            margin-left: auto;
            margin-top: 0.5rem;
            transition: opacity 0.3s ease;
            height: 1rem;

            &:hover {
                color: $roltek-blue;
            }

            &:active {
                opacity: 1;
            }
        }
    }

    &__checkmark {
        margin-left: auto;
        margin-top: 0.5rem;
        color: green;
        height: 1rem;
    }

    &__switch-wrapper {
        @include flex-center;

        span {
            margin-right: 5px;
        }
    }
}
