@import "@/assets/scss/style.scss";

.c-add-material-slot {
    display: flex;
    flex-direction: column;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-right: 8px;
    }

    &__select {
        width: 100%;
    }

    &__row {
        padding-top: 12px;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
    }

    &__label {
        padding-bottom: 8px;
    }

    &__input {
        width: 100%;
    }

    &__add {
        margin-top: 24px;
        align-self: center;
    }
}
