@import "@/assets/scss/style.scss";

.co-add-product-popup-slot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: $dark;
    position: relative;
    min-height: 100%;

    &__section {
        width: 100%;
    }

    &__upper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__product-label {
        margin: 2px;
    }

    &__product-label-title {
        padding-bottom: 5px;
    }

    &__error {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
    }

    &__loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.6);

        .anticon {
            font-size: 40px;
        }
    }

    &__add-product-button {
        margin-top: 30px;
        margin-left: 40px;
        color: $grey-9;
    }

    &__footer {
        padding: 16px 24px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        align-items: center;
        box-shadow: inset 0 1px 0 #e2e4e9;
        background-color: #fff;
        z-index: 100;
        width: 100%;

        & > * {
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
