@import "@/assets/scss/style.scss";

.user-settings-color {
    color: inherit;
}

.user-setttings-input {
    display: flex;
    align-items: center;
    justify-content: center;

    .menu-item-icon {
        display: flex;

        svg {
            color: inherit;
            width: 20px;
            height: 20px;
        }
    }

    .menu-item-label {
        font-size: 14px;
    }
}

.user-settings-select__username {
    color: $grey-9;
    font-weight: bold;
}

.collapsed-menu {
    display: none !important;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
