@import "@/assets/scss/style.scss";

.ant-radio-group {
    display: flex;
    flex-direction: column;

    .ant-radio-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 25px 0;
        padding-left: 35px;
        margin: 0;
        color: rgba(255, 255, 255, 0.5);
        background-color: transparent;
        border: none;
        transition: all 0.15s ease-in-out;

        .label {
            opacity: 0;
            transform: translateX(5px);
            display: none;
        }

        &:hover {
            color: #fff;
        }

        .animate-label {
            opacity: 1;
            display: block;
            transform: translateX(0);
        }

        .radio-button-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: max-content;

            .radio-button__icon-group {
                margin-right: 12px;
                width: max-content;
            }

            .radio-button__icon-primary {
                font-size: 20px;

                svg {
                    height: inherit;
                    width: inherit;
                }
            }
            .radio-button__icon-secondary {
                font-size: 14px;
                margin-left: -12px;
                background-color: #2a5aa4 !important;
            }
        }

        &-checked {
            border: none;
            border-left: 4px solid #fff;
            color: #fff;
            border-color: #fff !important;
            border-radius: 0 !important;
            box-shadow: none;

            .radio-button-content {
                margin-left: -4px;
            }

            &:hover {
                box-shadow: none;
                outline: none;
                border-left: 4px solid #fff;
                color: #fff;
            }
        }
    }

    .tab-0 {
        position: relative;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: $roltek-blue-6 !important;
            opacity: 0.7;
            position: absolute;
            top: 0;
            left: 0;
        }

        .radio-button__icon-primary {
        }
    }

    .reset-radio-button-style {
        border: none;
        color: rgba(255, 255, 255, 0.5);
    }

    .wrapper-small-screen {
        padding-left: 0;
    }

    .radio-button-content-collapsed {
        justify-content: center !important;

        @media (max-width: 992px) {
            width: 56px !important;
        }

        @media (min-width: 992px) {
            width: 88px !important;
        }
    }

    .icon-group-collapsed {
        margin: 0 !important;
    }
}
