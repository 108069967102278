@import "@/assets/scss/style.scss";

.delete-activity-btn {
    position: absolute;
    bottom: 10px;
    left: 20px;
}

.complete-activity-btn {
    position: absolute;
    top: 11px;
    right: 20px;
    float: right;
    color: $grey-8;
    border-color: $grey-8;
    &.completed {
        color: white;
        border-color: $green-5;
        background-color: $green-5 !important;
    }
}
.project-link {
    padding: 4px 11px;
    background: $grey-three;
    text-align: center;
    margin-bottom: 3px;
    .link {
        color: $blue-6;
    }
}
