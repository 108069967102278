@import "@/assets/scss/style.scss";

.nav-link {
    &__wrapper {
    }

    &__link {
        font-weight: bold;
        color: $roltek-blue;
    }
}
