@import "@/assets/scss/style.scss";

.ant-checkbox-wrapper {
    padding: 4px 2px;
}
.c-table-search-bar {
    // width: 700px;
    margin-bottom: 8px;

    &__filter {
        cursor: pointer;
    }

    &__filter-icon {
        margin-right: 10px;
    }

    &__filter-title {
        color: $black;
    }

    .search-label {
        @media screen and (max-width: 500px) {
            display: none;
        }
    }
    & /deep/ .ant-input-affix-wrapper .ant-input-suffix {
        // width: 5%;
        right: 6px;
        // top: 16px;
        height: 80%;
        .ant-btn {
            height: 100%;
            background: $grey-three;
            border: none;

            .anticon {
                @media screen and (max-width: 500px) {
                    margin: 0;
                }
            }
        }
        .ant-input-search-icon {
            display: none;
        }
    }
    & /deep/ .ant-input-affix-wrapper .ant-input-prefix {
        opacity: 0.65;
    }
    & /deep/ .ant-input-search {
        width: 98%;
        margin-right: auto;
        .ant-input {
            width: 100%;
        }
    }
    & /deep/ .ant-input-group-addon:last-child {
        padding: 4px 11px;
        border: 1px solid $grey-1;
        border-radius: 2px;
        width: 5%;
        min-width: 80px;
    }
    transition: padding-right 0.3s ease-in-out;
}
.are-filters-visible {
    transition: padding-right 0.3s ease-in-out;
    padding-right: 275px;
}
.sidebar-collapsed {
    padding-right: 0;
}
.active-filter-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .active-filter {
        background-color: $grey-4;
        margin-right: 10px;
        margin-bottom: 8px;
        padding: 2px 8px;
        display: block;
        height: auto;
        &.ant-btn {
            background-color: white;
        }
    }
}
