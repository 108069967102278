@import "@/assets/scss/style.scss";

.wrapper {
    width: 100%;
}

p {
    padding-bottom: 6px;
    color: rgba(0, 0, 0, 0.85);
}

.ant-select {
    width: 100%;
}
