@import "@/assets/scss/style.scss";

.cls-1 {
        fill: #fff;
      }

      .cls-2 {
        fill: none;
      }

      .cls-3 {
        fill: #e52521;
      }
