@import "@/assets/scss/style.scss";

.notification {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 7px 0;
    width: 100%;
    padding: 10px;
    border: 1px solid #f3f3f3;
    border-radius: 12px;

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.3rem;
        width: 100%;
    }

    &__text-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;
    }

    &__text {
        width: 95%;
        word-wrap: break-word;

        &--read {
            width: 100%;
        }
    }

    &__date {
        font-weight: bold;
        font-size: 0.8rem;
        opacity: 0.8;
        color: $roltek-blue;
    }

    &__circle {
        margin: 0.5rem 0.3rem 0 0;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: $roltek-blue;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
    &:hover {
        background-color: #f3f3f3;
    }
}
