@import "@/assets/scss/style.scss";

.editor {
    position: relative;
    width: 100%;

    &__title {
        margin-right: 12px;
        color: $black;
    }

    &__content {
        border-radius: 4px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        max-height: 150px;
        overflow-y: auto;

        &::v-deep {
            .ProseMirror {
                min-height: 60px;
                padding: 12px;

                &-focused {
                    outline: none;
                }
            }

            p.is-editor-empty:first-child::before {
                content: attr(data-empty-text);
                float: left;
                color: #aaa;
                pointer-events: none;
                height: 0;
                font-style: italic;
            }

            p {
                margin: 0;
                color: $black;
            }

            pre {
                padding: 0.7rem 1rem;
                border-radius: 5px;
                background: $black;
                color: $white;
                font-size: 0.8rem;
                overflow-x: auto;

                code {
                    display: block;
                }
            }

            p code {
                padding: 0.2rem 0.4rem;
                border-radius: 5px;
                font-size: 0.8rem;
                font-weight: bold;
                background: rgba($black, 0.1);
                color: rgba($black, 0.8);
            }

            ul,
            ol {
                padding-left: 1rem;
            }

            li > p,
            li > ol,
            li > ul {
                margin: 0;
            }

            a {
                color: $blue-6;
                text-decoration: underline;
            }

            blockquote {
                border-left: 3px solid rgba($black, 0.1);
                color: rgba($black, 0.8);
                padding-left: 0.8rem;
                font-style: italic;

                p {
                    margin: 0;
                }
            }

            img {
                max-width: 100%;
                border-radius: 3px;
            }

            table {
                border-collapse: collapse;
                table-layout: fixed;
                width: 100%;
                margin: 0;
                overflow: hidden;

                td,
                th {
                    min-width: 1em;
                    border: 2px solid $border-gray;
                    padding: 3px 5px;
                    vertical-align: top;
                    box-sizing: border-box;
                    position: relative;

                    > * {
                        margin-bottom: 0;
                    }
                }

                th {
                    font-weight: bold;
                    text-align: left;
                }

                .selectedCell:after {
                    z-index: 2;
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: rgba(200, 200, 255, 0.4);
                    pointer-events: none;
                }

                .column-resize-handle {
                    position: absolute;
                    right: -2px;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    z-index: 20;
                    background-color: #adf;
                    pointer-events: none;
                }
            }

            .tableWrapper {
                margin: 1em 0;
                overflow-x: auto;
            }

            .resize-cursor {
                cursor: ew-resize;
                cursor: col-resize;
            }
        }

        * {
            caret-color: currentColor;
        }
    }
}

.menubar {
    transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
    display: flex;
    align-items: center;

    &::v-deep {
        svg {
            path {
                fill: #8c8c8c;
            }
        }
    }

    &.is-hidden {
        visibility: hidden;
        opacity: 0;
    }

    &.is-focused {
        visibility: visible;
        opacity: 1;
        transition: visibility 0.2s, opacity 0.2s;
    }

    &__button {
        font-weight: bold;
        display: inline-flex;
        background: transparent;
        border: 0;
        color: $black;
        height: 22px;
        width: 30px;
        justify-content: center;
        align-items: center;
        margin-right: 0.2rem;
        border-radius: 3px;
        cursor: pointer;

        .anticon {
            font-size: 18px;
        }

        &:hover {
            background-color: $grey-4;
        }

        &.is-active {
            background-color: #e2edff;

            &::v-deep {
                svg {
                    path {
                        fill: $roltek-blue;
                    }
                }
            }
        }
    }

    span#{&}__button {
        font-size: 13.3333px;
    }
}

.menububble {
    position: absolute;
    display: flex;
    z-index: 20;
    background: $black;
    border-radius: 5px;
    padding: 0.3rem;
    margin-bottom: 0.5rem;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;

    &.is-active {
        opacity: 1;
        visibility: visible;
    }

    &__button {
        display: inline-flex;
        background: transparent;
        border: 0;
        color: $white;
        padding: 0.2rem 0.5rem;
        margin-right: 0.2rem;
        border-radius: 3px;
        cursor: pointer;
        justify-content: center;
        align-items: center;

        span {
            margin-right: 12px;
        }

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background-color: rgba($white, 0.1);
        }

        &.is-active {
            background-color: rgba($white, 0.2);
        }
    }

    &__form {
        display: flex;
        align-items: center;
    }

    &__input {
        font: inherit;
        border: none;
        background: transparent;
        color: $white;
    }
}

.is-disabled {
    opacity: 0.6;
    pointer-events: none;
}

.has-border {
    border: 1px solid $grey-1;
    padding-top: 6px;
}
